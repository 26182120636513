import { FeatureFlagScope } from './FeatureFlagScope.js';

const DefaultFeatureFlags = FeatureFlagScope.create({
  primer_react_css_modules_team: false,
  primer_react_css_modules_staff: false,
  primer_react_css_modules_ga: false,
  primer_react_action_list_item_as_button: false
});

export { DefaultFeatureFlags };
